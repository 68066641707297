<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Packaging Line Scanner</h2>
        </div>
    </div>
</div>
<div *ngIf="formCreated" class="col-md-12 col-lg-9">
    <form class="miles-form modern-css padded row" [formGroup]="skidForm" (ngSubmit)="formSubmitted()">
        <div class="row row-col-align-middle">
            <div class="col-sm-6">
                <dynamic-field #barcode [field]="abstractSkidControls.BacktenderBarcode" [form]="skidForm" (blur)="parseBarcode()"></dynamic-field>
            </div>
            <div class="col-sm-6"><label>Order Number: </label> {{ getOrderNumber() }}</div>
        </div>
        <div class="row row-col-align-middle">
            <div class="col-sm-7">
                <label>Customer: </label> {{ orderInfo?.Customer?.CustomerName }}
                <br />
                <label>Grade: </label> {{ getBoardGradeName() }}
                <br />
                <label>Finish: </label> {{ getFinishName() }}
                <br />
                <div class="row">
                    <div class="col-xs-4"><label>Size: </label> {{ getSize() }}</div>
                    <div class="col-xs-4"><label>Count: </label> {{ getOrderCount() }}</div>
                </div>
                <br />
            </div>
            <div class="col-sm-5">
                <label>Skid Number:</label> {{ skidNumber }}
                <div class="panel panel-default p-sm p-b-0">
                    <button class="btn btn-default btn-block btn-sm" type="button" (click)="overridePlyAndPiles()">Change</button>
                    <br />
                    <div class="row">
                        <div class="col-sm-4">
                            <dynamic-field [field]="abstractSkidControls.Piles" [form]="skidForm"></dynamic-field>
                        </div>
                        <div class="col-sm-4">
                            <dynamic-field [field]="abstractSkidControls.Ply" [form]="skidForm"></dynamic-field>
                        </div>
                        <div class="col-sm-4">
                            <dynamic-field [field]="abstractSkidControls.Caliper" [form]="skidForm"></dynamic-field>
                        </div>
                    </div>
                </div>
                <span class="pull-right">{{ scannerName }} Scanner</span>
            </div>
        </div>
        <div class="row row-col-align-middle m-b-sm">
            <div class="col-sm-6">
                <div class="panel panel-default p-sm m-0">
                    <div class="row">
                        <div class="col-md-6 text-center b-left b-right">
                            <div>
                                <label>Tare Weight:</label>
                            </div>
                            <div class="col-md-6">
                                <dynamic-field #tareWeightTop [field]="abstractSkidControls.TareWeightTop" [form]="skidForm"></dynamic-field>
                            </div>
                            <div class="col-md-6">
                                <dynamic-field [field]="abstractSkidControls.TareWeightBottom" [form]="skidForm"></dynamic-field>
                            </div>
                            <div class="clearfix"></div>
                            <h4 class="b-none m-0">
                                <label class="label label-default read-only-field">{{ getTotalTareWeight() }}</label>
                            </h4>
                        </div>
                        <div class="col-md-4">
                            <dynamic-field [field]="abstractSkidControls.SkidHeight" [form]="skidForm" (blur)="getCustomerCustomPackaging()"></dynamic-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3"></div>
            <div class="col-sm-3 float-end text-center">
                <div class="m-y-xs">
                    <button class="btn btn-default btn-sm" type="button" (click)="zeroScale()">Zero Scale</button>
                </div>
                <div class="m-y-xs">
                    <button class="btn btn-default btn-sm" type="button" (click)="showMillShiftPersonnelModal()">Clear Personnel Prompt</button>
                </div>
                <div class="m-y-xs">
                    <button class="btn btn-default btn-sm" type="button" (click)="testStack()">Test Stack</button>
                </div>
                <div class="m-y-xs">
                    <button class="btn btn-default btn-sm" type="button" (click)="noBarcodeTag()">No Barcode Tag</button>
                </div>
            </div>
        </div>

        <div class="panel panel-default p-sm text-center">
            <div class="row">
                <div class="col-sm-6">
                    <scale-override
                        [onSave]="onSave"
                        [label]="scaleLabel"
                        [skid]="skid"
                        (onOverride)="updateActualWeightOverride($event)"
                    ></scale-override>
                    <div>
                        <div class="col-xs-4">
                            <label>Gross</label>
                            <br />
                            {{ getActualWeight() }}
                        </div>
                        <div class="col-xs-4">
                            <label>Board</label>
                            <br />
                            {{ getBoardWeight() }}
                        </div>
                        <div class="col-xs-4">
                            <label>% Error</label>
                            <br />
                            <h4 class="b-none">
                                <label
                                    class="label"
                                    [ngClass]="{ 'label-success': scaleCountErrorPercentOkay(), 'label-danger': !scaleCountErrorPercentOkay() }"
                                    >{{ getScaleCountErrorPercent() | percent: '0.2-2' }}</label
                                >
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div>
                        <div>
                            <scanner-override
                                [onSave]="onSave"
                                [label]="scannerLabel"
                                bundlesLabel="Bundles"
                                [skid]="skid"
                                [isOverridingBundles]="!!actualBundles"
                                (onOverride)="updateActualCountOverride($event)"
                                (onOverrideBundles)="updateActualBundles($event)"
                            ></scanner-override>
                        </div>
                        <div class="col-xs-6"><label>Metro</label></div>
                        <div class="col-xs-6">
                            <label>% Error</label>
                            <br />
                            <h4 class="b-none">
                                <label
                                    class="label"
                                    [ngClass]="{ 'label-success': scanCountErrorPercentOkay(), 'label-danger': !scanCountErrorPercentOkay() }"
                                    >{{ getScanCountErrorPercent() | percent: '0.2-2' }}</label
                                >
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-3">
                <button type="button" class="btn btn-secondary btn-block font-weight-bold" (click)="weigh()" [disabled]="!skidLoaded || isWeighing">
                    {{ isWeighing ? 'WEIGHING...' : 'SCALE' }}
                </button>
            </div>
            <div class="col-xs-3">
                <button type="button" class="btn btn-secondary btn-block font-weight-bold" (click)="showScanModal()" [disabled]="!skidLoaded">
                    SHEETS
                </button>
            </div>
            <div class="col-xs-6">
                <button
                    type="submit"
                    mt-doubleClickDisabled
                    [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                    Class="btn btn-danger btn-block font-weight-bold"
                >
                    Process
                </button>
                <div class="m-t-xs">
                    <div *ngIf="isAboveErrorThreshold() && customErrorCountExceeded" class="text-danger">
                        <i class="fa fa-fw fa-lg fa-exclamation-circle"></i>
                        <small>You are outside of the customer specific error threshold. Please contact the front office</small>
                    </div>
                    <div *ngIf="isAboveErrorThreshold() && !customErrorCountExceeded" class="text-danger">
                        <i class="fa fa-fw fa-lg fa-exclamation-circle"></i>
                        <small>You are above the error threshold. Please double check before proceeding.</small>
                    </div>
                    <div *ngIf="!isAboveErrorThreshold() && !customErrorCountExceeded" class="text-success">
                        <i class="fa fa-fw fa-lg fa-check-circle"></i>
                        <small>Everything is good. Please continue.</small>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<modal-wrapper *ngIf="skid" title="Multiple Pile Sheet Counts" [options]="scanModalOptions" [autoShow]="false" (ready)="scanModal = $event">
    <div class="row">
        <div class="col-xs-8">
            <div class="row">
                <div class="col-xs-9">
                    <label class="pull-right">Target Sheet Count:</label>
                </div>
                <div class="col-xs-3">
                    {{ skid.TargetCount }}
                </div>
            </div>
            <div class="row">
                <div class="col-xs-9">
                    <label class="pull-right">Actual Count:</label>
                </div>
                <div class="col-xs-3">
                    {{ getActualCount() }}
                </div>
            </div>
            <div class="row">
                <div class="col-xs-9">
                    <label class="pull-right"># of Piles:</label>
                </div>
                <div class="col-xs-3">
                    {{ getSkidFormValues().Piles }}
                </div>
            </div>

        </div>
        <div class="col-xs-4 text-center">
            <label>% Error: </label>
            <br />
            {{ getScanCountErrorPercent() | percent: '0.2-2' }}
        </div>
    </div>
    <div class="panel panel-default p-sm m-t-sm">
        <div class="row row-col-align-middle p-y-xs" *ngFor="let pile of skid.SkidPiles; let i = index">
            <div class="col-xs-4">
                <label>Pile {{ i + 1 }}:</label>
            </div>
            <div class="col-xs-4">
                {{ pile.Count }}
            </div>
            <div class="col-xs-4">
                <button *ngIf="i < 2" class="btn btn-default btn-xs" (click)="scanPile(pile, i)" [disabled]="isScanning">
                    {{ isScanning && i === pileIndexBeingScanned ? 'Scanning...' : 'Scan Pile ' + (i + 1) }}
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="displaySameCountError()" class="text-danger">
        <i class="fa fa-fw fa-lg fa-exclamation-circle"></i>
        <small>MATCHING PILE SCAN COUNTS INDICATED!</small>
    </div>
    <div class="col-xs-12">
        <div class="row row-col-align-middle bg-danger p-y-xs" *ngIf="customErrorCountExceeded && getActualCount() !== 0">
            <span class="text text-danger">You are outside of the customer specific error threshold. Please contact the front office</span>
        </div>
    </div>
</modal-wrapper>

<modal-wrapper
    [options]="errorConfirmationModalOptions"
    type="warning"
    title="Warning!"
    [autoShow]="false"
    (ready)="errorConfirmationModal = $event"
    (okClick)="saveSkid()"
    (cancelClick)="enableDoubleClick()"
>
    <span>{{ errorConfirmationMessage }}</span>

</modal-wrapper>

<modal-wrapper
    *ngIf="showSuccessModal"
    [options]="successModalOptions"
    type="success"
    title="Success!"
    (okClick)="closeSuccessModal()"
>
    <h4>The skid has been created in the system with a total sheet count of</h4>
    <h3 class="text-center">{{ getActualCount() }}</h3>
    <div class="m-y-md">
        NOTE THE FOLLOWING INSTRUCTIONS:
        <div class="m-t-sm">
            <div *ngIf="orderSpecs?.TagSkidTypeId">{{ orderSpecs.TagSkidType.Name }}</div>
            <div *ngIf="orderSpecs?.BandingInstructionId">Banding: {{ orderSpecs.BandingInstruction.Name }}</div>
            <div *ngIf="orderSpecs?.IsNoNewmanMarkings">No Newman Markings</div>
            <div *ngIf="orderSpecs?.IsCornerProtectors">Corner Protectors</div>
            <div *ngIf="orderSpecs?.IsLtl">Extra Stretch Wrap</div>
            <div *ngIf="orderSpecs?.SpecialInstructions">{{ orderSpecs.SpecialInstructions }}</div>
        </div>
    </div>
    <h4>Mark the tag to ensure that you know that the skid was already processed.</h4>
</modal-wrapper>

<modal-wrapper
    *ngIf="currentMillShift"
    [options]="millShiftPersonnelModalOptions"
    title="Mill Personnel on Duty"
    [autoShow]="false"
    (ready)="millShiftPersonnelModal = $event"
>
    <div class="m-t-sm">
        <mill-shift-personnel [millShift]="currentMillShift" (onSave)="onMillShiftPersonnelCreated($event)"></mill-shift-personnel>
    </div>
</modal-wrapper>

<modal-wrapper title="Add Skid" [options]="noBarcodeModalOptions" customClass="swal-wide" [autoShow]="false" (ready)="noBarcodeModal = $event">
    <div class="m-t-sm">
        <no-barcode-skid (onAddSkid)="onNoBarcodeAddSkid($event)" (onCancel)="closeNoBarcodeModal()"></no-barcode-skid>
    </div>
</modal-wrapper>

<modal-wrapper
    [options]="barcodeExistsModalOptions"
    title="What do you want to do?"
    customClass="swal-wide"
    [autoShow]="false"
    (ready)="barcodeExistsModal = $event"
>
    <div class="m-t-sm text-center">
        <h4>This barcode ID already exists in the system</h4>
        <div class="row m-t-md">
            <div class="col-xs-8 col-xs-offset-2">
                <button type="button" (click)="printFinalSkidTag()" class="swal2-cancel swal2-styled btn-block">Reprint the skid tag</button>
                <br />
                <button type="button" (click)="closeBarcodeExistsDialog(false)" class="swal2-cancel swal2-styled btn-block">
                    Rescan/Reweigh this skid
                </button>
                <br />
                <button type="button" (click)="closeBarcodeExistsDialog(true)" class="swal2-cancel swal2-styled btn-block">
                    Cancel this operation
                </button>
            </div>
        </div>
    </div>
</modal-wrapper>

<modal-wrapper
    [options]="skidConvertingOrLiningModalOptions"
    title="What do you want to do?"
    customClass="swal-wide"
    [autoShow]="false"
    (ready)="skidConvertingOrLiningModal = $event"
>
    <div class="m-t-sm text-center">
        <h4 *ngIf="barcodeExists">{{ skidConvertingOrLiningHeader }}</h4>
        <div class="row m-t-md">
            <div class="col-xs-8 col-xs-offset-2">
                <button type="button" (click)="closeConvertingOrLiningModal(false, false)" class="swal2-cancel swal2-styled btn-block" *ngIf="barcodeExists">
                    {{ doNotCreateBarcodeText }}
                </button>
                <br />
                <button type="button" (click)="closeConvertingOrLiningModal(false, true)" class="swal2-cancel swal2-styled btn-block" *ngIf="_skid?.Id > 0">
                    {{ doCreateBarcodeText }}
                </button>
                <br />
                <button type="button" (click)="closeConvertingOrLiningModal(true, false)" class="swal2-cancel swal2-styled btn-block">
                    Cancel this operation
                </button>
            </div>
        </div>
    </div>
</modal-wrapper>
