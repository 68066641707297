import { ChangeDetectorRef, Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SkidService } from '../skid.service';
import { MillShiftService } from '../millshift.service';
import { MillShiftPersonnelService } from '../millshiftpersonnel.service';
import { FractionsService } from '@common/services/fractions.service';
import { PrintService } from '@common/services/print.service';

import { SkidDynamicControlsPartial } from '@model/partials/skid.form-controls';

import { IMillShift } from '@model/interfaces/mill-shift';
import { ISkid } from '@model/interfaces/skid';
import { ISkidPile } from '@model/interfaces/skid-pile';
import { IOrderInfo } from '@model/interfaces/order-info';
import { IBoardGrade } from '@model/interfaces/board-grade';
import { IFinish } from '@model/interfaces/finish';

import { IMillShiftPersonnel } from '@model/interfaces/mill-shift-personnel';
import { finalize } from 'rxjs/operators';
import { Scales } from '@model/Scales';
import { Scanners } from '@model/Scanners';
import { ScalesService } from '../../scales/scales.service';
import { ScannersService } from '../../scanners/scanners.service';
import { IModalOptions, IModalResult, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { StockItemsService } from '../../sales-orders/stockitem.service';
import { CustomPackagingService } from '../../customers/custom-packaging/custom-packaging.service';
import { ICustomPackaging } from '@model/interfaces/custom-packaging';
import { IOrderCustomerSpecification } from '@model/interfaces/order-customer-specification';
import { HttpErrorResponse } from '@angular/common/http';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { IConvertingSkid } from '@model/interfaces/converting-skid';
import { BacktenderService } from '../../trim-sheets/backtender.service';
import { IBacktenderBarcode } from '@model/interfaces/backtender-barcode';
import { TagSkidTypes } from '@model/TagSkidTypes';

interface IDayShift {
    MillShift: IMillShift;
    ShiftStart: Date;
}

@Component({
    selector: 'packaging-line',
    styleUrls: ['packaging-line-scanner.component.css'],
    templateUrl: './packaging-line-scanner.component.html',
})
export class PackagingLineScannerComponent implements OnInit {
    backtenderBarcode: string;
    // abstract controls
    abstractSkidControls: any;

    skidForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    orderInfo: IOrderInfo;
    orderSpecs: IOrderCustomerSpecification;

    _skid: ISkid;

    get skid(): ISkid {
        return this._skid;
    }
    set skid(skid) {
        this._skid = skid;
        this.orderInfo = skid?.SalesOrder?.OrderInfo;
        this.orderSpecs = skid?.SalesOrder?.OrderInfo?.OrderCustomerSpecification;
    }

    // skid number parts
    salesOrderId: number;
    skidNumber: number;
    sequenceNumber: string;

    currentMillShift: IMillShift;
    nextMillShift: IMillShift;
    todaysShifts: IDayShift[];
    currentMillShiftPersonnel: IMillShiftPersonnel;
    isShiftChanging = false;
    shiftChangeTimeout = null;

    skidLoaded = false;
    formIsLocked = false;

    isLining = false;
    isConverting = false;
    finalizeSkid = true;
    doNotCreateBarcodeText = '';
    doCreateBarcodeText = '';
    skidConvertingOrLiningHeader = '';

    scannerName: string;
    scannerId: number;
    isScanning = false;
    pileIndexBeingScanned: number;
    scannerLabel = 'Pieces';
    actualCountOverride: number = null;

    scaleId: number;
    isWeighing = false;
    scaleLabel = 'Weight';
    actualWeightOverride: number = null;
    customPackaging: ICustomPackaging[] = null;
    selectedCustomPackaging: ICustomPackaging = null;
    customErrorCountExceeded = false;
    errorConfirmationMessage: string;

    showBundlesInput = false;
    actualBundles: number;

    onSave = false;

    private readonly errorThreshold = 0.2;

    @ViewChild('barcode', { read: ElementRef }) barcodeElement: ElementRef;
    @ViewChild('tareWeightTop', { read: ElementRef }) tareWeightTopElement: ElementRef;

    errorConfirmationModal: IModalWrapperApi;
    errorConfirmationModalOptions: IModalOptions = {
        focusConfirm: true,
        showCancelButton: true,
        showConfirmButton: false,
    };

    showSuccessModal = false;
    successModalOptions: IModalOptions = {
        allowEscapeKey: false,
        allowOutsideClick: false,
        focusCancel: false,
        showCancelButton: false,
    };

    millShiftPersonnelModal: IModalWrapperApi;
    millShiftPersonnelModalOptions: IModalOptions = {
        allowEscapeKey: !this.isShiftChanging,
        allowOutsideClick: !this.isShiftChanging,
        focusCancel: false,
        showCancelButton: false,
        showConfirmButton: false,
        width: '800px',
    };

    noBarcodeModal: IModalWrapperApi;
    noBarcodeModalOptions: IModalOptions = {
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
    };

    barcodeExistsModal: IModalWrapperApi;
    barcodeExistsModalOptions: IModalOptions = {
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
    };

    skidConvertingOrLiningModal: IModalWrapperApi;
    skidConvertingOrLiningModalOptions: IModalOptions = {
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
    };

    scanModal: IModalWrapperApi;
    scanModalOptions: IModalOptions = {
        confirmButtonText: 'Close',
        focusCancel: false,
        showCancelButton: false,
    };

    get salesOrder(): ISalesOrder {
        return this.skid?.SalesOrder;
    }

    get canPrintConvertingOrderFinalTag(): boolean {
        return this.isConverting && !!this.orderSpecs?.BandingInstructionId;
    }

    backtenderBarcodeObj: IBacktenderBarcode;
    barcodeExists: boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private skidService: SkidService,
        private millShiftService: MillShiftService,
        private millShiftPersonnelService: MillShiftPersonnelService,
        private scalesService: ScalesService,
        private scannersService: ScannersService,
        private stockItemsService: StockItemsService,
        private customPackagingService: CustomPackagingService,
        private backtenderService: BacktenderService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.backtenderBarcode = this.route.snapshot.paramMap.get('backtenderBarcode');
        forkJoin([this.millShiftService.getItems(), this.millShiftPersonnelService.getCurrent()]).subscribe((answer) => {
            this.currentMillShiftPersonnel = answer[1];
            this.setShiftChangeTimer();
            this.createForm();
            this.focusSkidNumber();
            this.getScaleTypeFromRoute();
            this.updateErrorConfirmationMessage();
        });
    }

    ngOnDestroy(): void {
        clearTimeout(this.shiftChangeTimeout);
    }

    createForm(): void {
        this.skid = this.skidService.getEmptySkid();
        this.skidLoaded = false;
        this.getControls();
        this.skidForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getScaleTypeRoutePart(): string {
        return this.route.snapshot.url[this.route.snapshot.url.length - 1].toString();
    }

    getScaleTypeFromRoute(): void {
        let urlpart = this.getScaleTypeRoutePart();
        if (urlpart === 'drj') {
            this.scaleId = Scales.PackagingLine_DrJ;
            this.scannerName = 'Dr. J';
            this.scannerId = Scanners.DrJ;
        } else if (urlpart === 'dry-end') {
            this.scaleId = Scales.PackagingLine_DryEnd;
            this.scannerName = 'Dry End';
            this.scannerId = Scanners.DryEnd;
        } else {
            this.scaleId = Scales.PackagingLine_AI;
            this.scannerName = 'A.I.';
            this.scannerId = Scanners.AI;
        }
    }

    setShiftChangeTimer(): void {
        this.todaysShifts = [];

        this.millShiftService.items.forEach((shift) => {
            let shiftTimeParts = shift.StartTime.split(':'); // it will never be null!
            let now = new Date();
            now.setHours(parseInt(shiftTimeParts[0], 10));
            now.setMinutes(parseInt(shiftTimeParts[1], 10));
            now.setSeconds(parseInt(shiftTimeParts[2], 10));
            this.todaysShifts.push({
                MillShift: shift,
                ShiftStart: now,
            });
        });

        // find the next shift based on the current time
        let now = new Date();
        let nextShiftIndex = -1;
        let nextShift = this.todaysShifts.find((shift, i) => {
            nextShiftIndex = i;
            return shift.ShiftStart > now;
        });

        if (!nextShift) {
            // they must be currently working the last shift of the calendar day
            // add 1 day to the shifts and then just take the first one
            this.todaysShifts.forEach((shift) => {
                shift.ShiftStart.setDate(shift.ShiftStart.getDate() + 1);
            });

            nextShift = this.todaysShifts[0];
            nextShiftIndex = 0;
        }

        let currentShiftIndex = nextShiftIndex - 1;
        if (currentShiftIndex < 0) {
            currentShiftIndex = this.todaysShifts.length - 1;
        }

        this.currentMillShift = this.todaysShifts[currentShiftIndex].MillShift;
        this.nextMillShift = nextShift.MillShift;

        let msUntilShiftChange = nextShift.ShiftStart.getTime() - now.getTime();
        this.shiftChangeTimeout = setTimeout(() => {
            this.isShiftChanging = true;
            // second setTimeout is just for the above boolean to be set (probably a better way?)
            setTimeout(() => {
                this.setShiftChangeTimer();
                this.millShiftPersonnelModal.show();
            });
        }, msUntilShiftChange);
    }

    clearOrder(): void {
        this.salesOrderId = null;
        this.sequenceNumber = null;
        this.skidNumber = null;
        this.skid = this.skidService.getEmptySkid();
        this.skidLoaded = false;
    }

    clearForNext(): void {

        this.skidForm.reset();

        let formControls = this.getSkidFormControls();
        formControls.BacktenderBarcode.enable();

        this.actualCountOverride = null;
        this.actualWeightOverride = null;
        this.onSave = false;

        this.clearOrder();
        this.clearBundles();
    }

    loadSkid(skid: ISkid): void {
        if (skid != null) {
            this.skid = skid;
            this.salesOrderId = skid.SalesOrderId;
            this.skidNumber = skid.SkidNumber;
            this.sequenceNumber = skid.Sequence;

            this.actualBundles = skid.ActualBundles;
            this.showBundlesInput = !!skid.ActualBundles;

            let formControls = this.getSkidFormControls();
            formControls.Piles.disable();
            formControls.Ply.disable();
            formControls.Caliper.disable();
            this.formIsLocked = true;

            this.isLining = skid.SalesOrder.ManufacturingOrderDetail.IsLiningDept;
            this.isConverting = skid.SalesOrder.ManufacturingOrderDetail.IsConverting;
            const showConvertingOrLiningModal =
                skid.SalesOrder && ((skid.SalesOrder.ManufacturingOrderDetail && (this.isConverting || this.isLining)) || skid.OrderDetailLineId > 0);

            if (skid.Id > 0 && skid.FinalBarcode) {
                // this skid was already processed; give the user options on what they want to do
                this.barcodeExistsModal.show();
            } else if (showConvertingOrLiningModal) {
                // This skid has not been sent to converting yet; prompt user to cancel or override
                if (this.isLining) {
                    this.skidConvertingOrLiningHeader = 'This skid belongs to a Lining Order';
                    this.doNotCreateBarcodeText = 'Print a Lining Tag';
                    this.doCreateBarcodeText = 'Print Finished Skid Tag';
                    this.skidConvertingOrLiningModal.show();
                }
                if (this.isConverting) {
                    this.skidConvertingOrLiningHeader = 'This skid belongs to a Converting Order';
                    this.doNotCreateBarcodeText = 'To Converting - Cut/Paste';
                    this.doCreateBarcodeText = 'Final Tag - To Customer';
                    this.backtenderService.getBacktenderBarcodeForSkid(skid.SalesOrderId, skid.SkidNumber).subscribe((backtenderBarcode) => {
                        this.barcodeExists = backtenderBarcode != null;
                        this.skidConvertingOrLiningModal.show();
                    });
                }

            } else {
                this.initSkid();
            }
        } else {
            this.invalidSkidNumber();
        }
    }

    updateSkidFromOrder(): void {
        this.skid.Piles = this.salesOrder.ManufacturingOrderDetail.Piles;
        this.skid.Ply = 1; // Default from BuildNewSkidFromOrderDetails
        this.skid.Width = this.salesOrder.ManufacturingOrderDetail.Width;
        this.skid.Length = this.salesOrder.ManufacturingOrderDetail.Length;
        this.skid.Count = this.salesOrder.ManufacturingOrderDetail.Count;
        this.skid.Caliper = this.salesOrder.ManufacturingOrderDetail.Caliper;

        const BdlSk = this.salesOrder.ManufacturingOrderDetail.BdlSk ?? 0;
        const poundsPerBundle = this.orderSpecs && this.orderSpecs.IsFortyPoundBundle ? 40 : 50;

        this.skid.TargetWeight = Math.round(BdlSk * poundsPerBundle);
        this.skid.TargetCount = Math.round(BdlSk * this.skid.Count);

        // Clear properties that need to be entered for final barcode
        this.skid.ActualWeight = null;
        this.skid.ActualCount = null;
        // Carry over previously entered tare. See clearForNext()
        this.skid.TareWeightBottom = this.getSkidFormValues().TareWeightBottom;
        this.skid.TareWeightTop = this.getSkidFormValues().TareWeightTop;
        this.skid.SkidHeight = null;
    }

    parseBarcode(): void {
        this.clearOrder();
        let barcode = this.getSkidFormValues().BacktenderBarcode;

        if (!barcode) {
            return;
        }

        this.skidService.getByBacktenderBarcode(barcode).subscribe((skid) => {
            if (skid) {
                this.loadSkid(skid);
                this.backtenderService.getBacktenderBarcodeForSkid(this.salesOrderId, this.skidNumber).subscribe((backtenderBarcode) => {
                    this.barcodeExists = backtenderBarcode != null;
                });
            } else {
                this.stockItemsService.getByStockIdNumber(barcode).subscribe((stockItem) => {
                    if (stockItem) {
                        window.open(`/#/stock-skid-processing?stockId=${barcode}`);
                    } else {
                        this.invalidSkidNumber();
                    }
                });
            }
            this.getCustomerCustomPackaging();
        });
    }

    onSheetsOpen(): void {
        let skidFormValues = this.getSkidFormValues();
        let emptyPile = {
            Count: null,
            Id: 0,
            SkidId: 0,
        };

        if (!this.skid.SkidPiles) {
            this.skid.SkidPiles = [];
        }

        if (skidFormValues.Piles > this.skid.SkidPiles.length) {
            // they increased the piles after the popup was opened
            for (let i = this.skid.SkidPiles.length; i < skidFormValues.Piles; i++) {
                this.skid.SkidPiles.push({ ...emptyPile });
            }
        } else {
            // they decreased it; slice off the amount that changed
            let numberToRemove = this.skid.SkidPiles.length - skidFormValues.Piles;
            this.skid.SkidPiles.splice(skidFormValues.Piles, numberToRemove);
        }
    }

    scanPile(pile: ISkidPile, i: number): void {
        let vals = this.getSkidFormValues();
        let skidHeight = vals.SkidHeight;
        if (!skidHeight) {
            this.notificationsService.warning('Skid height is required before scanning.');
            return;
        }

        let caliper = vals.Caliper;
        if (!caliper) {
            this.notificationsService.warning('Caliper is required before scanning.');
            return;
        }

        let ply = vals.Ply;
        if (!ply) {
            this.notificationsService.warning('Ply is required before scanning.');
            return;
        }

        if (this.isScanning) {
            return;
        }

        this.isScanning = true;
        this.pileIndexBeingScanned = i;
        this.scannersService
            .getSheetCount(this.scannerId, this.skid.BacktenderBarcode, caliper, skidHeight, ply)
            .pipe(
                finalize(() => {
                    this.isScanning = false;
                    this.pileIndexBeingScanned = null;
                    this.actualBundles = null;
                }),
            )
            .subscribe(
                (answer) => {

                    pile.Count = answer;

                    // check to see if there are more than two piles
                    if (this.skid.SkidPiles.length > 2 && this.skid.SkidPiles[0].Count && this.skid.SkidPiles[1].Count) {
                        // get the avg of the first and second pile count
                        let avg = (this.skid.SkidPiles[0].Count + this.skid.SkidPiles[1].Count) / 2;
                        // assign average to the ret of the piles
                        let j = 2;
                        while (j < (this.skid.SkidPiles.length)) {
                            this.skid.SkidPiles[j].Count = avg;
                            j++;
                        }
                    }
                },
                (errorResponse: HttpErrorResponse) => {
                    // formatting the error to only show up to the first breakpoint
                    let response = errorResponse.error.toString().split(/\r\n/);
                    this.notificationsService.error(response[0], 'Error occurred when retrieving sheet count', { timeOut: 7500 });
                },
            );
    }

    getScanCountErrorPercent(): number {
        if (this.actualBundles) {
            return 0;
        }
        return this.getActualCount() / this.skid.TargetCount - 1;
    }

    getScanCountErrorCustom(): boolean {
        if (this.selectedCustomPackaging) {
            let count = this.getActualCount();
            const lowThreshold = this.selectedCustomPackaging.MustCount - this.selectedCustomPackaging.Minus;
            const highThreshold = this.selectedCustomPackaging.MustCount + this.selectedCustomPackaging.Plus;
            return count >= lowThreshold && count <= highThreshold ? false : true;
        }
        return false;
    }

    scanCountErrorPercentOkay(): boolean {
        if (this.selectedCustomPackaging) {
            let result = this.getScanCountErrorCustom();
            this.setCustomErrorCountExceededBool(result);
            return result;
        }
        return Math.abs(this.getScanCountErrorPercent()) <= 0.2;
    }

    setCustomErrorCountExceededBool(value: boolean): void {
        this.customErrorCountExceeded = value;
    }

    displaySameCountError(): boolean {
        let pileCounts = this.skid.SkidPiles?.map((pile) => {
            return pile.Count;
        }).filter((count) => count);

        return pileCounts?.length > 0 ? pileCounts.filter(this.filterUniqueCounts).length < pileCounts.length : false;
    }

    filterUniqueCounts(value: number, index: number, self: Array<number>): any {
        return self.indexOf(value) === index;
    }

    weigh(): void {
        this.isWeighing = true;
        this.scalesService
            .getWeight(this.scaleId)
            .pipe(finalize(() => (this.isWeighing = false)))
            .subscribe((weight) => {
                this.skid.ActualWeight = Math.round(weight);
            });
    }

    getScaleCountErrorPercent(): number {
        return this.getActualWeight() / this.skid.TargetWeight - 1;
    }

    scaleCountErrorPercentOkay(): boolean {
        return Math.abs(this.getScaleCountErrorPercent()) <= 0.01;
    }

    overridePlyAndPiles(): void {
        let formControls = this.getSkidFormControls();
        formControls.Piles.enable();
        formControls.Ply.enable();
        formControls.Caliper.enable();
        this.formIsLocked = false;
    }

    getSkidFormValues(): ISkid {
        return this.skidForm.getRawValue().Skid;
    }

    getSkidFormControls(): any {
        return (this.skidForm.controls.Skid as FormGroup).controls;
    }

    initSkid(): void {
        if (this.skid.Id > 0 && !this.skid.IsConverting) {
            this.getSkidValues();
        } else {
            this.populateSkidFromPrevious();
        }

        let formControls = this.getSkidFormControls();
        formControls.BacktenderBarcode.enable();

        this.skidForm.patchValue({ Skid: this.skid });

        formControls.BacktenderBarcode.disable();

        this.focusTareWeightTop();
        this.skidLoaded = true;
    }

    populateSkidFromPrevious(): void {
        let alreadyProcessedSkids: ISkid[]|IConvertingSkid[] = this.skid.SalesOrder.Skids.filter((x) =>
            (!x.IsConverting) ||
            (this.finalizeSkid && x.FinalBarcode) ||
            (!this.finalizeSkid && !x.FinalBarcode),
        )
        .sort(function compare(a, b): number {
            return new Date(a.DateProcessed).getTime() - new Date(b.DateProcessed).getTime();
            });

        // if it's converting, there have been other skids sent to converting, but they are now finalized, pull from the converting table
        if (this.skid.IsConverting && !this.finalizeSkid && !alreadyProcessedSkids.length) {
            alreadyProcessedSkids = this.salesOrder.ConvertingSkids.filter((x) => x.DateProcessed)
            .sort(function compare(a, b): number {
                return new Date(a.DateProcessed).getTime() - new Date(b.DateProcessed).getTime();
                });
        }

        if (alreadyProcessedSkids.length > 0) {
                let lastSkid = alreadyProcessedSkids[alreadyProcessedSkids.length - 1];

                this.skid.TareWeightTop = lastSkid.TareWeightTop;
                this.skid.TareWeightBottom = lastSkid.TareWeightBottom;
                this.skid.SkidHeight = lastSkid.SkidHeight;
        } else {
            this.skid.TareWeightTop = 0;
            this.skid.TareWeightBottom = 0;
            this.skid.SkidHeight = 0;
        }

    }

    getSkidValues(): void {

        let formValues = this.getSkidFormValues();
        this.skid.TareWeightTop = formValues.TareWeightTop;
        this.skid.TareWeightBottom = formValues.TareWeightBottom;
        this.skid.SkidHeight = formValues.SkidHeight;
    }

    getOrderNumber(): string {
        return this.skid && this.skid.SalesOrder ? this.skid.SalesOrder.Id.toString() : null;
    }

    getBoardGrade(): IBoardGrade {
        return this.skid ? this.skid.BoardGrade : null;
    }

    getFinish(): IFinish {
        return this.skid ? this.skid.Finish : null;
    }

    getBoardGradeName(): string {
        let boardGrade = this.getBoardGrade();
        return boardGrade ? boardGrade.Name : null;
    }

    getFinishName(): string {
        let finish = this.getFinish();
        return finish ? finish.Name : null;
    }

    getCustomerCustomPackaging(): void {
        let customer = this.orderInfo?.Customer;
        if (customer) {
            this.customPackagingService.getCustomPackagingByCustomerId(customer.Id).subscribe((result) => {
                if (!result) {
                    this.customPackaging = null;
                    this.selectedCustomPackaging = null;
                    return;
                }
                this.customPackaging = result;
                let height = this.salesOrder.ManufacturingOrderDetail.Height;
                let matchedPackaging = this.customPackaging.filter((x) => {
                    return (
                        (!x.Height || x.Height === height) &&
                        (!x.BoardGradeId || x.BoardGradeId === this.skid.BoardGradeId) &&
                        x.Caliper === this.skid.Caliper &&
                        x.Length === this.skid.Length &&
                        x.Width === this.skid.Width
                    );
                });
                if (matchedPackaging.length === 1) {
                    this.selectedCustomPackaging = matchedPackaging[0];
                } else if (matchedPackaging.length > 1) {
                    this.selectedCustomPackaging = this.customPackaging.find(
                        (cp) =>
                            (cp.Height === height && cp.BoardGradeId === this.skid.BoardGradeId) ||
                            cp.Height === height ||
                            cp.BoardGradeId === this.skid.BoardGradeId,
                    );
                } else {
                    this.selectedCustomPackaging = null;
                }
            });
        } else {
            this.customPackaging = null;
            this.selectedCustomPackaging = null;
        }
    }

    getSize(): string {
        if (!this.skid) {
            return null;
        }

        return this.skid.Width + ' x ' + this.skid.Length;
    }

    getOrderCount(): number {
        if (!this.skid) {
            return null;
        }

        return this.skid.Count;
    }

    getActualCount(): number {
        if (this.actualCountOverride) {
            return this.actualCountOverride;
        }
        if (!this.skid.SkidPiles?.length) {
            return this.skid.ActualCount ? this.skid.ActualCount : null;
        }

        let totalCount = 0;
        this.skid.SkidPiles.forEach((item) => (totalCount += item.Count));
        return totalCount;
    }

    getActualWeight(): number {
        if (this.actualWeightOverride) {
            return this.actualWeightOverride;
        }
        if (!this.skid.ActualWeight) {
            return null;
        }
        return this.skid.ActualWeight;
    }

    getAvgCount(count?: number): number {
        let actualCount = count || this.skid.ActualCount || this.getActualCount();
        let poundsPerBundle = this.orderSpecs && this.orderSpecs.IsFortyPoundBundle ? 40 : 50;
        let weight = this.getBoardWeight();
        let actualBundles = weight / poundsPerBundle;

        let avgCount = actualCount / actualBundles;
        return avgCount;
    }

    getTotalTareWeight(): number {
        let formValues = this.getSkidFormValues();
        return formValues.TareWeightTop + formValues.TareWeightBottom;
    }

    getBoardWeight(): number {
        return this.getActualWeight() ? this.getActualWeight() - this.getTotalTareWeight() : null;
    }

    getControls(): void {
        this.abstractSkidControls = new SkidDynamicControlsPartial(this.skid, null).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            Skid: this.fb.group({}),
        });
    }

    focusSkidNumber(): void {
        this.barcodeElement.nativeElement.children[1].children[0].children[0].children[1].children[0].focus();
        if (this.backtenderBarcode) {
            (this.skidForm.controls.Skid as FormGroup).controls.BacktenderBarcode.setValue(this.backtenderBarcode);
            this.parseBarcode();
        }
    }

    focusTareWeightTop(): void {
        this.tareWeightTopElement.nativeElement.children[1].children[0].children[0].children[1].children[0].children[0].children[0].focus();
    }

    isValid(): boolean {
        if (this.skid.SkidPiles.every((item) => item.Count == null) || this.actualCountOverride) {
            // If the scan is overridden or the user opened 'Sheets' modal and closed without scanning
            // anything then remove the empty SkidPiles that were created, otherwise the user will not
            // be able to override and save.
            this.skid.SkidPiles = [];
        }

        let unscannedPileExists =
            !this.skid.SkidPiles ||
            this.skid.SkidPiles.some((item) => {
                return item.Count === null;
            });

        if (unscannedPileExists) {
            this.notificationsService.warning('You must scan all piles before processing');
            return false;
        }

        if (this.getActualWeight() === null) {
            this.notificationsService.warning('You must use the scale before processing');
            return false;
        }

        return true;
    }

    isAboveErrorThreshold(): boolean {
        if (this.selectedCustomPackaging) {
            return (
                this.getScanCountErrorCustom() ||
                isNaN(this.getScaleCountErrorPercent()) ||
                Math.abs(this.getScaleCountErrorPercent()) > this.errorThreshold
            );
        } else {
            this.setCustomErrorCountExceededBool(false);
        }
        return (
            isNaN(this.getScanCountErrorPercent()) ||
            Math.abs(this.getScanCountErrorPercent()) > this.errorThreshold ||
            isNaN(this.getScaleCountErrorPercent()) ||
            Math.abs(this.getScaleCountErrorPercent()) > this.errorThreshold
        );
    }

    buildSkidToSave(): ISkid {
        let vals = this.getSkidFormValues();
        let skid: ISkid = { ...this.skid };

        skid.BacktenderBarcode = vals.BacktenderBarcode;
        skid.SkidHeight = vals.SkidHeight;
        skid.Piles = vals.Piles;
        skid.Ply = vals.Ply;
        skid.Caliper = vals.Caliper;
        skid.TareWeightTop = vals.TareWeightTop;
        skid.TareWeightBottom = vals.TareWeightBottom;
        skid.ActualCount = this.selectedCustomPackaging ? this.selectedCustomPackaging.MustCount : this.getActualCount();
        skid.ActualWeight = this.getActualWeight();
        skid.AvgCount = this.getAvgCount(skid.ActualCount);

        if (this.actualBundles) {
            skid.ActualBundles = this.actualBundles;
            skid.ActualCount = null;
        } else {
            skid.ActualBundles = null;
        }
        // static
        skid.SalesOrderId = this.salesOrderId;
        skid.SkidNumber = this.skidNumber;
        skid.Sequence = this.sequenceNumber;
        skid.ScaleId = this.scaleId;
        skid.DateProcessed = new Date();
        skid.ProcessedBy = 0;
        skid.MillShiftPersonnelId = this.currentMillShiftPersonnel.Id;

        skid.OrderDetailLine = null;
        skid.SalesOrder = null;
        skid.BoardGrade = null;
        skid.Finish = null;

        return skid;
    }

    saveSkid(): void {
        let skid = this.buildSkidToSave();
        let apiPromise = null;
        let validatePiles = skid.SkidPiles?.length ? true : false;

        if (skid.Id > 0) {
            apiPromise = this.skidService.update(skid, validatePiles, this.finalizeSkid);
        } else {
            apiPromise = this.skidService.create(skid, null, null, this.finalizeSkid);
        }

        apiPromise.pipe(finalize(() => this.enableDoubleClick())).subscribe((answer) => {
            this.skid.Id = answer;
            this.showSuccessModal = true;
            this.onSave = true;
        });
    }

    formSubmitted(): void {
        if (this.skidForm.valid) {
            if (this.isValid()) {
                if (this.isAboveErrorThreshold()) {
                    this.updateErrorConfirmationMessage();
                    this.errorConfirmationModal.show();
                } else {
                    this.saveSkid();
                }
            } else {
                this.enableDoubleClick();
            }
        } else {
            common.markAllFormFieldsAsTouched(this.skidForm);
            this.enableDoubleClick();
        }
    }

    closeSuccessModal(): void {
        if (this.skid.FinalBarcode !== '' || this.finalizeSkid) {
            this.printFinalSkidTag();
        }
        this.clearForNext();
        this.focusSkidNumber();
        this.showSuccessModal = false;
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    testStack(): void {
        // TODO: fix this to be ScaleId and not ScaleTypeId
        this.router.navigate(['/test-stack/' + this.getScaleTypeRoutePart()]);
    }

    zeroScale(): void {
        // TODO: implement this logic
        this.notificationsService.info('Nothing here yet...');
    }

    showMillShiftPersonnelModal(): void {
        this.millShiftPersonnelModal.show();
    }

    closeMillShiftPersonnelModal(): void {
        this.clearForNext();
        this.focusSkidNumber();
        this.millShiftPersonnelModal.close();
    }

    onMillShiftPersonnelCreated(millShiftPersonnel): void {
        this.currentMillShiftPersonnel = millShiftPersonnel;
        this.isShiftChanging = false;
        this.closeMillShiftPersonnelModal();
    }

    noBarcodeTag(): void {
        this.noBarcodeModal.show();
    }

    onNoBarcodeAddSkid(answer): void {
        this.closeNoBarcodeModal();
        this.loadSkid(answer);
    }

    closeNoBarcodeModal(): void {
        this.noBarcodeModal.close();
    }

    printFinalSkidTag(): void {
        let tagSkidTypeId = this.skid?.SalesOrder?.OrderInfo?.OrderCustomerSpecification?.TagSkidTypeId;
        let numberOfCopies = 1;
        switch (tagSkidTypeId) {
            case TagSkidTypes.Tag_1:
                numberOfCopies = 1;
                break;
            case TagSkidTypes.Tag_2:
                numberOfCopies = 2;
                break;
            case TagSkidTypes.Tag_4:
                numberOfCopies = 4;
                break;
            default:
                break;
        }
        this.skidService.getFinalTag(this.skid.Id).subscribe(async(answer) => {
            await PrintService.printPdf(answer, numberOfCopies);
            this.closeBarcodeExistsDialog(true);
            this.clearBundles();
        });
    }

    closeBarcodeExistsDialog(clearForNext: boolean): void {
        if (clearForNext) {
            this.clearForNext();
        } else {
            this.initSkid();
        }

        this.barcodeExistsModal.close();
    }

    closeConvertingOrLiningModal(clearForNext: boolean, finalizeSkid: boolean): void {
        if (clearForNext) {
            this.clearForNext();
        } else if (!this.canPrintConvertingOrderFinalTag && finalizeSkid) {
            this.notificationsService.warning('The Converting Sales Order is Missing Banding Instructions, please add the Banding Instructions before printing the Final Tag');
            return;
        } else {
            this.finalizeSkid = finalizeSkid;

            if (this.finalizeSkid) {
                this.updateSkidFromOrder();
            }

            this.initSkid();
        }

        this.skidConvertingOrLiningModal.close();
    }

    invalidSkidNumber(): void {
        this.notificationsService.warning('Invalid skid number');
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    showScanModal(): void {
        this.onSheetsOpen();
        this.scanModal.show();
    }

    updateActualCountOverride(override: number): void {
        this.actualCountOverride = override;
        this.actualBundles = null;
    }

    updateActualWeightOverride(override: number): void {
        this.actualWeightOverride = override;
    }

    updateErrorConfirmationMessage(): void {
        this.errorConfirmationMessage =
            this.customErrorCountExceeded === true
                ? `You are outside of the customer specific error threshold. Please contact the front office`
                : `You are above the current error threshold. Are you sure you wish to continue?`;
        this.errorConfirmationModalOptions.showConfirmButton = this.customErrorCountExceeded === true ? false : true;
    }

    clearBundles(): void {
        this.showBundlesInput = false;
        this.actualBundles = null;
    }

    updateActualBundles(bundles: number): void {
        this.actualBundles = bundles;
        this.actualCountOverride = null;
    }
}
