import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { RotaryTypeService } from '../rotary-type.service';
import { RotarySlitDivisionLogService } from './rotary-slit-division-log.service';

import { RotarySlitDivisionLogDynamicControlsPartial } from '@model/partials/rotary-slit-division-log-partial.form-controls';
import { IRotarySlitDivisionLog } from '@model/interfaces/rotary-slit-division-log';
import { RotaryTypeIds } from '@model/RotaryTypes';
import { distinctUntilChanged, filter, finalize } from 'rxjs/operators';
import { IExpandableObject } from '@model/expandable-object';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { SalesOrderService } from '../../sales-orders/sales-order.service';
import { OrderTypeIds } from '@model/OrderTypes';
import { BoardTypeIds } from '@model/BoardTypes';
import { IConvertingDataEntryUser } from '@model/interfaces/converting-data-entry-user';
import { ConvertingDataEntryUserService } from '../converting-data-entry-user.service';
import { IDynamicField } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'rotary-slit-division-log-entry',
    styles: [
        `
            hr {
                border-color: #999;
            }
        `,
    ],
    templateUrl: './rotary-slit-division-log-entry.component.html',
})
export class RotarySlitDivisionLogEntryComponent implements OnInit {
    abstractRotarySlitDivisionLogControls: IExpandableObject;
    rotarySlitDivisionLogEntryForm: FormGroup;

    doubleClickIsDisabled = false;
    initialRows = 5;
    hoveredIndex = -1;
    loadingLogs = false;

    isNewReport: boolean;
    selectedDate: Date;
    currentReportId: number;
    maxReportId: number;

    get rotarySlitDivisionLogFormArray(): FormArray {
        return <FormArray>this.rotarySlitDivisionLogEntryForm.controls.RotarySlitDivisionLogs;
    }

    convertingDataEntryUsers: IConvertingDataEntryUser[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private rotaryTypeService: RotaryTypeService,
        private rotarySlitDivisionLogService: RotarySlitDivisionLogService,
        private salesOrderService: SalesOrderService,
        private convertingDataEntryUserService: ConvertingDataEntryUserService,
    ) {}

    ngOnInit(): void {
        forkJoin([this.rotaryTypeService.getItems(), this.convertingDataEntryUserService.getItems()]).subscribe(([, users]) => {
            // sort the items by id, not alphabetical which is the default
            this.rotaryTypeService.items.sort((a, b) => {
                return a.Id < b.Id ? -1 : 1;
            });

            this.convertingDataEntryUsers = users;
            this.createForm();
        });
    }

    createForm(): void {
        this.abstractRotarySlitDivisionLogControls = new RotarySlitDivisionLogDynamicControlsPartial(this.convertingDataEntryUsers, null, {
            formGroup: 'RotarySlitDivisionLogHeader',
            rotaryTypes: this.rotaryTypeService.items,
        }).Form;

        this.rotarySlitDivisionLogEntryForm = this.fb.group({
            RotarySlitDivisionLogHeader: this.fb.group({}),
            RotarySlitDivisionLogs: this.fb.array([]),
        });

        this.addDetail(this.initialRows);
    }

    dateControlCreated(dateControl: FormControl): void {
        dateControl.valueChanges
            .pipe(
                filter((date) => date !== null),
                distinctUntilChanged((x: Date, y: Date) => x.getTime() === y.getTime()),
            )
            .subscribe((date) => this.selectDate(date));
    }

    selectDate(date: Date): void {
        this.maxReportId = null;
        this.dateSelected(date);
    }

    dateSelected(date: Date, reportId: number = null): void {
        this.selectedDate = date;
        if (reportId === null || (reportId > 0 && this.maxReportId !== null && reportId <= this.maxReportId) || this.isNewReport) {
            this.loadingLogs = true;
            this.rotarySlitDivisionLogService.getByDate(date, reportId).subscribe((logs) => {
                this.rotarySlitDivisionLogFormArray.clear();
                this.addDetail(logs.length || this.initialRows);
                // Give added detail lines time to render
                setTimeout(() => {
                    if (logs.length) {
                        this.currentReportId = logs[0].ReportId;
                        this.maxReportId = this.maxReportId ?? this.currentReportId;
                        this.rotarySlitDivisionLogEntryForm.get('RotarySlitDivisionLogHeader').patchValue(logs[0]);
                        this.rotarySlitDivisionLogEntryForm.get('RotarySlitDivisionLogs').patchValue(logs);
                        this.patchConvertingDataEntryUsers(logs[0]);
                    } else {
                        this.maxReportId = null;
                        this.rotarySlitDivisionLogEntryForm.get('RotarySlitDivisionLogHeader').reset({ Date: date });
                        this.resetConvertingDataEntryUsers();
                    }

                    this.loadingLogs = false;
                });
            });
            if (!this.isNewReport) {
                this.currentReportId = null;
            }
            this.isNewReport = false;
        } else {
            this.showNoReportsWarning();
        }
    }

    private patchConvertingDataEntryUsers(log: IRotarySlitDivisionLog): void {
        (<IDynamicField>this.abstractRotarySlitDivisionLogControls.Operator1).value = log.Operator1;
        (<IDynamicField>this.abstractRotarySlitDivisionLogControls.Operator2).value = log.Operator2;
    }

    private resetConvertingDataEntryUsers(): void {
        (<IDynamicField>this.abstractRotarySlitDivisionLogControls.Operator1).value = '';
        (<IDynamicField>this.abstractRotarySlitDivisionLogControls.Operator2).value = '';
    }

    showNoReportsWarning(): void {
        this.notificationsService.warning('No more reports exist for this date');
    }

    buildPayload(): IRotarySlitDivisionLog[] {
        let logs: IRotarySlitDivisionLog[] = [];
        let headerValues = this.rotarySlitDivisionLogEntryForm.value.RotarySlitDivisionLogHeader;

        // check each detail and only add it to the payload if it's pre-existing or has been edited
        this.rotarySlitDivisionLogFormArray.controls.forEach((details: FormGroup) => {
            const value = details.getRawValue();
            if (value.Id > 0 || details.dirty) {
                let log = this.rotarySlitDivisionLogService.getEmptyRotarySlitDivisionLog();
                log.ReportId = this.currentReportId ?? 1;
                log.Date ??= this.selectedDate;
                Object.assign(log, headerValues);
                Object.assign(log, details.getRawValue());
                log.SalesOrderId = log.SalesOrderId === 0 ? null : log.SalesOrderId;
                logs.push(log);
            }
        });
        return logs;
    }

    addDetail(qty = 1): void {
        for (let i = 1; i <= qty; i++) {
            this.rotarySlitDivisionLogFormArray.push(
                this.fb.group({
                    Id: this.fb.control({ value: 0, disabled: true }), // this is a hidden field to track Ids for existing logs
                }),
            );
        }
    }

    removeDetail(index: number): void {
        this.rotarySlitDivisionLogFormArray.removeAt(index);
    }

    pullSalesOrderValues(index: any): void {
        if (this.loadingLogs) {
            return;
        }

        let formRow = this.rotarySlitDivisionLogFormArray.at(index);
        if (!formRow) {
            return;
        }

        let salesOrderId: number = (formRow as FormGroup).controls.SalesOrderId.value;
        if (!salesOrderId) {
            return;
        }

        // get the sales order

        forkJoin([this.salesOrderService.getById(salesOrderId), this.salesOrderService.getSkids(salesOrderId)]).subscribe(([salesOrder, skids]) => {
            if (!salesOrder) {
                this.notificationsService.error(`Sales Order ${salesOrderId} not found`);
            } else {
                if (skids) {
                    salesOrder.Skids = skids;
                }
                this.pullManufacturingOrderDetailData(salesOrder, formRow);
            }
        });
    }

    pullManufacturingOrderDetailData(salesOrder: ISalesOrder, formRow: AbstractControl): void {
        if (!salesOrder.ManufacturingOrderDetail) {
            return;
        }
        // default data to manufacturing order detail data
        const mfod = salesOrder.ManufacturingOrderDetail;

        let width = mfod.Width;
        let length = mfod.Length;
        let caliper = mfod.Caliper;
        let count = mfod.Count;
        let skids = mfod.NumberOfSkids;
        let bps = mfod.BdlSk;
        let bundles = mfod.Quantity;
        let grade = '';
        let boardTypeId = BoardTypeIds.MilledBoard;
        let isTiedBundle = salesOrder.OrderInfo.OrderCustomerSpecification.IsTiedBundles;
        let pieces = salesOrder.Skids.reduce((sum, sk) => sum + sk.ActualCount, 0);
        let lining = salesOrder.ManufacturingOrderDetail.Lining?.Name ?? salesOrder.ManufacturingOrderDetail.LiningCustom ?? '';
        let customerName = salesOrder.OrderInfo.Customer.CompanyName;
        let lastSkid = 0;
        let sheetsPerSkid = 0;
        let totalSheets = 0;

        if (salesOrder.Skids.length > 0) {
            totalSheets = salesOrder.Skids.reduce((sum, sk) => sum + sk.ActualCount, 0);
            sheetsPerSkid = Math.round(totalSheets / salesOrder.Skids.length);

            lastSkid = salesOrder.Skids.sort(function compare(a, b): number {
                return new Date(a.DateProcessed).getTime() - new Date(b.DateProcessed).getTime();
            })[0].SkidNumber;
        }

        if (salesOrder.OrderTypeId === OrderTypeIds.Stock) {
            boardTypeId = BoardTypeIds.StockBoard;
        } else {
            boardTypeId = BoardTypeIds.MilledBoard;
            grade = salesOrder.ManufacturingOrderDetail.BoardGrade?.Name;
        }

        formRow.patchValue({
            BdlSk: bps,
            BoardGrade: grade,
            BoardTypeId: boardTypeId,
            Bundles: bundles,
            Caliper: caliper,
            Count: count,
            CustomerName: customerName,
            LastSkid: lastSkid,
            Length: length,
            Lining: lining,
            Pieces: pieces,
            SheetsPerSkid: sheetsPerSkid,
            Skids: skids,
            TiedBundles: isTiedBundle,
            TotalSheets: totalSheets,
            Width: width,
        });
    }

    formSubmitted(): void {
        if (this.rotarySlitDivisionLogEntryForm.valid) {
            let payload = this.buildPayload();
            if (payload.length === 0 && this.rotarySlitDivisionLogFormArray.length === 0) {
                this.rotarySlitDivisionLogService
                    .deleteAllByDate(this.selectedDate)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe(() => {
                        this.notificationsService.success('All Rotary Slit Reports deleted successfully');
                        this.resetForm();
                        this.dateSelected(this.selectedDate);
                    });
            } else if (payload.length > 0) {
                this.rotarySlitDivisionLogService
                    .saveByDate(payload)
                    .pipe(
                        finalize(() => {
                            this.enableDoubleClick();
                        }),
                    )
                    .subscribe((ids) => {
                        this.rotarySlitDivisionLogFormArray.patchValue(ids.map((id) => ({ Id: id })));
                        this.notificationsService.success('Rotary Slit Division Report saved successfully');
                        this.dateSelected(this.selectedDate);
                    });
            } else {
                this.notificationsService.warning('There is no data to save.');
                this.enableDoubleClick();
            }
        } else {
            common.markAllFormFieldsAsTouched(this.rotarySlitDivisionLogEntryForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    getDefaultHeaderValue(): IRotarySlitDivisionLog {
        let val = this.rotarySlitDivisionLogService.getEmptyRotarySlitDivisionLog();
        val.RotaryTypeId = RotaryTypeIds.RotarySlitter;
        return val;
    }

    resetForm(): void {
        this.rotarySlitDivisionLogEntryForm.reset();
        this.rotarySlitDivisionLogEntryForm.controls.RotarySlitDivisionLogHeader.patchValue(this.getDefaultHeaderValue());

        let numDetails = this.rotarySlitDivisionLogFormArray.controls.length;
        for (let i = numDetails - 1; i >= 0; i--) {
            this.removeDetail(i);
        }
        for (let i = 0; i < numDetails; i++) {
            this.addDetail();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['/'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    navigateToFirstReportOfTheDay(): void {
        this.dateSelected(this.selectedDate, 1);
    }

    navigateToPreviousReport(): void {
        this.dateSelected(this.selectedDate, this.currentReportId - 1);
    }

    navigateToNextReport(): void {
        this.dateSelected(this.selectedDate, this.currentReportId + 1);
    }

    navigateToLatestReportOfTheDay(): void {
        this.dateSelected(this.selectedDate);
    }

    getNewReport(): void {
        this.isNewReport = true;
        this.currentReportId = this.maxReportId + 1;
        this.dateSelected(this.selectedDate, this.currentReportId);
    }

    calculateTotalSheets(group: FormGroup): void {
        const sheetsPerSkid = group.controls.SheetsPerSkid.value;
        const skids = group.controls.Skids.value;
        if (sheetsPerSkid && skids) {
            group.controls.TotalSheets.setValue(sheetsPerSkid * skids, { emitEvent: false });
        }
    }

    calculateSheetsPerSkid(group: FormGroup): void {
        const totalSheets = group.controls.TotalSheets.value;
        const skids = group.controls.Skids.value;
        if (totalSheets && skids) {
            group.controls.SheetsPerSkid.setValue(Math.round(totalSheets / skids), { emitEvent: false });
        }
    }
}
