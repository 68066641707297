import {
    IEntityListConfig,
    IEntityListColumn,
    IEntityListSelectColumn,
    SelectTypes,
    EntityListConfig,
    EntityListColumn,
} from '@mt-ng2/entity-list-module';
import { IStockItem } from '../../model/interfaces/stock-item';
import { truncateToOneDecimal } from '@common/common-functions.form-controls';

export class AvailableInventoryListConfig extends EntityListConfig {
    columns: IEntityListColumn[];
    select: IEntityListSelectColumn;

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['StockIdNumber'],
                    name: 'Stock Id:',
                }),
                new EntityListColumn({
                    accessors: ['LotNumber'],
                    name: 'Lot #:',
                }),
                new EntityListColumn({
                    accessorFunction: (item: IStockItem) => (item.Bundles ? truncateToOneDecimal(item.Bundles) : ''),
                    name: 'Qty:',
                    sort: {
                        sortProperty: 'Bundles',
                    },
                }),
                new EntityListColumn({
                    accessors: ['Width'],
                    name: 'Width:',
                }),
                new EntityListColumn({
                    accessors: ['Length'],
                    name: 'Length:',
                }),
                new EntityListColumn({
                    accessorFunction: (item: IStockItem) => (item.CaliperMax ? item.CaliperMax.toFixed(3) : ''),
                    name: 'Calip:',
                    sort: {
                        sortProperty: 'CaliperMax',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (item: IStockItem) => (item.BoardGrade ? item.BoardGrade.Abbreviation : ''),
                    name: 'Type:',
                    sort: {
                        isDefaultForSort: true,
                        sortProperty: 'BoardGrade.Abbreviation',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (item: IStockItem) => (item.TopColor || item.BottomColor ? this.formatStockItemColor(item) : ''),
                    name: 'Color (Top/Bottom)',
                    sort: {
                        sortProperty: 'TopColor.Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (item: IStockItem) => (item.WarehouseLocation ? item.WarehouseLocation.Name : ''),
                    name: 'Loc:',
                    sort: {
                        sortProperty: 'WarehouseLocation.Name',
                    },
                }),
            ],
        };
        super(listConfig);
    }

    formatStockItemColor(stockItem: IStockItem): string {
        return (stockItem.TopColor?.Name || '') + ' / ' + (stockItem.BottomColor?.Name || '');
    }
}
